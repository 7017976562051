<template>
  <div class="userCenter">
    <div class="userInfo">
      <img :src="userInfo.avatar" class="userAvatar" alt="" />
      <div class="userRightInfo">
        <div class="userName">{{ userInfo.username }}</div>
        <div class="userDescription">{{ userInfo.introduction }}</div>
        <div class="certificate">
          {{ userInfo.status && userInfo.status.name }}
        </div>
      </div>
    </div>
    <div class="menulist">
      <router-link to="/userCenter/course">
        <div class="menu">
          <img src="@/assets/img/userCenter/course.png" alt="" />
          我的课程
        </div>
      </router-link>
      <router-link to="/userCenter/order">
        <div class="menu">
          <img src="@/assets/img/userCenter/order.png" alt="" />
          我的订单
        </div>
      </router-link>
    </div>
    <div style="text-align: center; margin-top: 40px">
      <el-button class="resign" type="default" @click="resign"
        >退出登录</el-button
      >
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {};
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    resign() {
      window.localStorage.setItem("CSON_PAGE_TOKEN", "");
      window.location.href = "#/";
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.userCenter {
  margin-top: 54px;
  .userInfo {
    margin: 20px 0;
    padding: 40px;
    background: #fbfbfb;
    display: flex;
    flex-wrap: nowrap;
  }
  .userAvatar {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    margin-right: 12px;
    vertical-align: top;
  }
  .userRightInfo {
    width: calc(100% - 80px);
    .userName {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      line-height: 24px;
    }
    .userDescription {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 20px;
    }
    .certificate {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #fa6400;
      line-height: 18px;
      text-decoration: underline;
    }
  }
}
.menulist {
  padding: 8px;
  .menu {
    padding: 12px 32px;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid #f1f1f1;
    img {
      vertical-align: top;
      margin-right: 4px;
    }
  }
}
.resign {
  width: 200px;
  margin: 0 auto;
}
</style>